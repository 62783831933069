import Vue from 'vue'
import App from './App.vue'
import { router } from './router';

Vue.config.productionTip = false

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import plugins from "./helpers/";
Vue.use(plugins);


// SVG inline
import InlineSvg from 'vue-inline-svg';
Vue.component('inline-svg', InlineSvg);

new Vue({
  router,
  //store,
  render: h => h(App)
}).$mount('#app');
