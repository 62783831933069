import Vue from 'vue';
import Router from 'vue-router';

/* FRONT */
const Home = () => import(/* webpackChunkName: "appmaster" */ "./views/Home.vue");
const Contact = () => import(/* webpackChunkName: "appmaster" */ "./views/Contact.vue");
const About = () => import(/* webpackChunkName: "appmaster" */ "./views/About.vue");

// import store from "./store";

Vue.use(Router);
// vue meta

export const router = new Router({
  mode: 'history',  
  routes: [

    {
      path: "/",
      name: "Home",
      component: Home
    },
    {
      path: "/kontakt",
      name: " contact",
      component: Contact
    },
    {
      path: "/om",
      name: "about",
      component: About
    },
    {
      path: "*",
      component: Error
    }
  ]
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/loggain'];
  const loggedIn = true;//localStorage.getItem('user');  
  var isPublicPage = checkPage(publicPages, to.path);

  if (isPublicPage) {
    next();
    return;
  }

  if (loggedIn) {
    next();
  }

  // If not logged in and trying to access a page that is nog public
  if (!loggedIn) {
    //store.dispatch("auth/logout");
    if (to.path != "" && to.path != "/")
      next({ path: "/loggain", query: { redirect: to.path } }); // Redirect to login page
    else
      next({ path: "/loggain" }); // Redirect to login page
    return;
  }
  // If accessing root path "/"
  else if (to.path === "/") {
    if (loggedIn) {
      next(); // Redirect after logged in
    }
    else {
      next({ path: "/loggain" }); // Redirect to login page
    }
    return;
  }

});

function checkPage(pages, path) {
  for (var i = 0; i < pages.length; i++) {
    var page = pages[i];
    if (path.includes(page))
      return true;
  }
  return false;
}
