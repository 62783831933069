export default {
      svg(svgImg)
      {
            const url = require("@/assets/svg/" + svgImg);
            return url;
      },
      pageImage(path)
      {
            const url = require("@/assets/images/pages/" + path);
            return url;
      } 
};
